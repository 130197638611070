// примениться к размеру от 1536 до 1919px
@media screen and (max-width: 1919px) {
  .wrapper__big {
    padding: 0 50px;
  }
  .wrapper {
    padding: 0 100px;
  }
  .header {
    &__logo {
      max-width: 12rem;
    }

    &__icons {
      gap: 1rem;
    }
  }
  .mb-sect, section {
    margin-bottom: 11rem;
  }
  .mb-100 {
    margin-bottom: 4rem;
  }
  .title {
    font-size: 2.2rem;
  }

  .main {
    margin-top: 10rem;

    &__image {
      width: 38%;
    }

    &__title {
      font-size: 4rem;
    }

    &__bull {
      gap: 1rem;

      li {
        font-size: 1.2rem;
      }
    }
  }

  .otzivy {
    gap: 7rem;

    &__img {
      width: 35%;
    }

    &__content {
      max-width: 755px;
    }
  }
  .conkurent {
    &__img {
      max-width: 500px;
    }
  }

  .products {
    &__link {
      font-size: 1.2rem;
    }
  }
  .price__subtitle {
    font-size: 1.2rem;
  }

  .akcii__img {
    max-width: 500px;
  }

  .vakancii__img {
    max-width: 500px;
  }
}

// примениться к размеру от 1366п до 1535п
@media screen and (max-width: 1535px) {
  html {
    font-size: 14px;
  }
  .mb-sect, section {
    margin-bottom: 10rem;
  }
  .wrapper__big {
    padding: 0 40px;
  }
  .mb-50 {
    margin-bottom: 2.7rem;
  }
  .header {
    gap: 0 1.2rem;

    &__links {
      gap: 0 1.2rem;
    }

    &__phones {
      flex-grow: 2;
    }

    &__phone {
      font-size: 1.3rem;
    }
  }
  .main {
    margin-top: 9rem;

    &__title {
      margin-bottom: 3rem;
    }
  }

  .otzivy {
    gap: 2rem;

    &__content {
      max-width: 715px;
    }
  }
}

// примениться к размеру от 1024px до 1365
@media screen and (max-width: 1317px) {
  .header {
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: .5rem;

    &__logo {
      order: 1;
    }

    &__links {
      order: 4;
      width: 100%;
      justify-content: space-between;
    }

    &__link {
      padding: 1.3rem 0;
    }

    &__icons {
      order: 2;

    }

    &__phones {
      flex-grow: 0;
      order: 3;
    }

  }
}

// примениться к размеру от 1024px до 1365
@media screen and (max-width: 1365px) {
  .wrapper {
    padding: 0 70px;
  }


  .mb-100 {
    margin-bottom: 3rem;
  }
  .mb-sect, section {
    margin-bottom: 8rem;
  }
  .main {
    margin-top: 12rem;

    &__title {
      font-size: 3rem;
      margin-bottom: 2rem;
    }

    &__bull {
      li {
        font-size: 1rem;

      }
    }
  }
  .otzivy__content {
    max-width: 500px;
  }

  .sertifikaty {
    gap: 3rem;

    &__elem {
      img {
        height: 25rem;
      }
    }
  }
  .contacts__content {
    gap: 4rem;
  }

  .felial {
    gap: 1.5rem;
    padding: 1.6rem;

    &__img {
      width: 12rem;
      height: 12rem;
    }
  }

  .portfolio {
    &__images {
      gap: 1rem;
    }

    &__videos {
      margin-top: 1rem;
      gap: 1rem;
    }
  }

  .conkurent {
    gap: 4rem;

    &__img {
      flex-shrink: initial;
      max-width: 340px;
    }
  }

  .akcii {
    gap: 4rem;

    &__img {
      max-width: 340px;
    }
  }

  .vyezd {
    gap: 4rem;

    &__img {
      max-width: 400px;
    }
  }

  .vakancii {
    &__list {
      &_elem {
        font-size: 1.2rem;
        margin-bottom: 1.1rem;
      }
    }

    &__img {
      max-width: 370px;
    }

    &__warning {
      margin-top: 2.4rem;
    }
  }

  .garantii_content {
    margin-bottom: 1rem;
  }

  .rassrochka {
    &__content {
      gap: 4rem;
    }

    &__img {
      flex-shrink: 1;
    }

    &__list {
      padding-left: 2rem;
    }
  }
  .footer {
    &_grid {
      gap: 2rem;
    }

    &_elem {
      &_like {
        font-size: 14px;
        padding: 20px;

        p {
          br {
            display: none;
          }
        }
      }

      &_contacts {
        &_wrapper {
          flex-wrap: wrap;
          gap: 20px;
        }

        p {
          font-size: 12px;
          line-height: 140%;
        }

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    &_prav {
      font-size: 12px;
    }
  }
}

// примениться к размеру от 768 px до 1023
@media screen and (max-width: 1023px) {
  .wrapper {
    padding: 0 3rem;
  }
  .quiz__content {
    padding: 0 2rem;

    &_elem {
      width: auto;
    }
  }
  .grid-3 {
    grid-template-columns: 1fr 1fr;
  }
  .main {
    margin-top: 10rem;

    &__button {
      &:before {
        width: 1.1rem;
        height: 1.6rem;
      }
    }
  }
  .btn {
    padding: 1.2rem 4rem;
    font-size: 1.3rem;
  }

  .portfolio__images {
    grid-template-columns: 1fr 1fr;
  }

  .sertifikaty__elem img {
    height: 20rem;
  }
  .contacts {
    &__title {
      font-size: 1.2rem;
      margin-bottom: 0;
    }
  }
  .otzivy {
    &__title {
      img {
        max-width: 50%;
      }
    }

    &__content {
      max-width: 447px;
    }
  }
  .products {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  .felial {
    &__text {
      gap: 1rem;
    }

    &__title {
      font-size: 1.2rem;
      margin-bottom: 0;
    }

    &__link {
      width: 13rem;
    }
  }
  .conkurent__img, .akcii__img {
    display: none;
  }
  .skidki {
    gap: 2rem;
  }
  .vyezd {
    &__content {
      flex-wrap: wrap;
      gap: 2rem;
    }

    &__img {
      width: 100%;
      max-width: none;
    }

    &__text {
      grid-template-columns: 1fr 1fr 1fr;
      justify-items: center;
    }
  }
  .vakancii__img, .rassrochka__img {
    display: none;
  }
  .garantii_content {
    gap: 2rem;
  }
  .questions__list {
    gap: .8rem 1rem;
  }
  .footer {
    &_grid {
      grid-template-columns: 1fr;
    }

    &_elem {
      &_like {
        font-size: 14px;
        padding: 20px;

        p {
          br {
            display: none;
          }
        }
      }

      &_links {
        line-height: 140%;
      }

      &_contacts {
        &_wrapper {
          flex-wrap: wrap;
          gap: 0 40px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    &_prav {
      font-size: 12px;
      line-height: 140%;
    }
  }

}

@media screen and (max-width: 767px) {
  .wrapper, .wrapper__big {
    padding: 0 15px;
  }
  .mb-sect, section {
    margin-bottom: 100px;
  }
  .mb-100 {
    margin-bottom: 40px;
  }
  .mb-50 {
    margin-bottom: 20px;
  }
  .grid-3, .grid-2 {
    grid-template-columns: 1fr;
  }
  .title {
    font-size: 20px;
  }

  .carousel__dots {
    display: flex;
  }
  .carousel__nav {
    display: none;
  }
  .table {
    overflow-x: auto;

    table {
      min-width: 450px;

    }
  }


  .header {
    position: static;
    margin-top: 3.5rem;
    flex-wrap: nowrap;
    border-bottom: none;

    &__burger {
      z-index: 11;
      position: fixed;
      left: 0;
      top: 0;
      padding: 10px 0 10px 10px;
      width: 100%;
      display: block;
      background-color: var(--textColor);
    }

    &__logo {
      width: 160px;
      flex-shrink: 0;
    }

    &__phones {
      padding: 5px 0;
      z-index: 11;
      position: fixed;
      top: 3px;
      right: 10px;
      color: #fff;
    }

    &__open_time {
      display: none;
    }

    &__icons {
      gap: 10px;
    }

    &__icon {
      width: 30px;

      &:first-child {
        background: url("../images/icons/whats__mob.webp") no-repeat center center;
        width: 30px;
        height: 30px;
        background-size: contain;
      }

      &:nth-child(2) {
        background: url("../images/icons/phone__mob.webp") no-repeat center center;
        width: 30px;
        height: 30px;
        background-size: contain;
      }

      &:last-child {
        background: url("../images/icons/calc__mob.webp") no-repeat center center;
        width: 30px;
        height: 30px;
        background-size: contain;
      }

      img {
        display: none;
      }
    }

    &__links {
      position: fixed;
      flex-direction: column;
      height: 100%;
      min-height: auto;
      width: 100%;
      left: -120%;
      top: 0;
      background-color: var(--accent);
      z-index: 3;
      padding: 60px 20px 40px;
      justify-content: center;
      gap: 0;
      transition: all .4s;
      overflow-y: auto;

      &.-active {
        left: 0;
        display: block;
      }
      &_submenu li {
        position: static;
        &:hover{
          &>ul {
            width: 100%;
            display: block;
            position: static;
            height: auto;
            max-height: none;
            overflow-y: auto;

          }
        }
      }
    }

    &__link {
      padding: 1rem 0;
    }
  }

  .main {
    margin-top: 2rem;

    &__title {
      font-size: 30px;
      margin-bottom: 20px;
    }

    &__content {
      padding: 0 10px;
    }

    &__image {
      display: none;
    }

    &__bull {
      li {
        &:before {
          width: 17px;
          height: 15px;
          margin-right: 11px;
        }
      }
    }
  }

  .quiz {
    &__wrapper {
      padding-bottom: 0;
    }

    &__content {
      padding: 0;
      grid-template-columns: 1fr;
      gap: 10px;

      &_elem {
        padding: 10px;
        min-height: 3.666rem;
      }

      &_span {
        justify-content: flex-start;
        padding-left: 3.5rem;

        &:before {
          left: 5%;
          bottom: 46%;
          transform: translateY(50%);
          width: 24px;
          height: 24px;
        }
      }
    }

    &__zagolovok {
      font-size: 17px;
      font-weight: 600;
      margin-bottom: 20px;
      line-height: 130%;

      .-sub_title {
        font-size: 1rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
      }
    }

    &__wrapper {
      border: none;
    }
  }

  .otzivy {
    &__content {
      width: 100%;
    }

    &__img {
      display: none;
    }

    &__title {
      gap: 1rem;

      img {
        max-width: 35%;
      }
    }
  }
  #otzyvy-portfolio {
    img {
      max-width: 150px;
    }

  }


  .sertifikaty {
    flex-wrap: wrap;
    gap: 1.2rem;
    justify-content: center;
  }

  .contacts {
    &__mails, &__content {
      flex-direction: column;
      gap: 1.6rem;
    }

    &__rekvizity__data {
      border-top: none;
    }

    &__scheme {
      margin-top: 4rem;
      margin-bottom: 0;
    }

    &__title {
      margin-bottom: 0 !important;
    }

    &__map {
      width: 100%;
    }
  }

  .otzivy__slider__elem {
    padding: 0;
  }

  .felial {
    padding: 10px;
    gap: 1rem;

    &__img {
      width: 110px;
      height: 110px;
      flex-shrink: 0;
    }

    &__title {
      font-size: 15px;
    }

    &__text {
      gap: 10px;
    }

    &__phone {
      margin-bottom: 0;
    }

    &__address {
    }

    &__link {
      width: 140px;
    }
  }

  .conkurent__content {
    width: 100%;
  }

  .komanda {
    display: block;
    position: relative;
    &__elem {
      width: var(--carousel-slide-width,100%);
      padding: 0 2rem;
    }
  }

  .search {
    flex-wrap: wrap;
    height: auto;

    &__form, &__filter {
      height: 50px;
      width: 100%;
      max-width: none;
    }

  }
  .suggestions {
    top: 40%;
    width: 100%;
  }
  .products {
    grid-template-columns: 1fr;

    &__elem {
      padding: 20px 10px 16px;
    }

    &__link {
      font-size: 18px;

      &::after {
        content: none;
      }
    }

    &__price {
      font-size: 20px;
      border: 2px solid var(--accent);
      padding: 1rem;
      border-radius: 60px;
      margin: 0 auto;
      display: inline-block;
      width: fit-content;
    }
  }

  .price {
    &__subtitle {
      font-size: 15px;

      &:before {
        width: 17px;
        height: 15px;
        margin-right: 5px;
      }
    }

    &__table {
      table {
        min-width: 600px;

        td {
          width: auto !important;
          padding-left: .5rem !important;
          padding-right: .5rem !important;
        }
      }

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .akcii {
    &__subtitle {
      font-size: 15px;
    }

    &__text {
      grid-template-columns: 1fr 1fr;
    }
  }

  .skidki {
    gap: 10px;
  }

  .vyezd {
    &__text {
      grid-template-columns: 1fr 1fr;
    }
  }

  .vakancii {
    &__list {
      padding-left: 12px;

      &_elem {
        font-size: 15px;

        &:before {
          width: 17px;
          height: 15px;
          margin-right: 11px;
        }
      }
    }

    &__warning {
      padding: 20px 10px;
    }

    &__text {
      line-height: 130%;
    }

    &__phone {
      font-size: 18px;
    }
  }

  .garantii {
    &_content {
      flex-wrap: wrap;

      .-img {
        order: 1;

        img {
          max-height: 230px;
          width: auto;
        }
      }

      .-text {
        order: 2;
      }

      ul li {
        margin-bottom: .5rem;
      }
    }
  }

  .rassrochka {
    &__title {
      font-size: 15px;
      text-align: left;

      &:before {
        margin-right: 10px;
      }
    }

    &__list {
      padding-left: 10px;
    }
  }

  .questions {
    &__content {
      flex-wrap: wrap;
      gap: 20px;
    }

    &__img {
      flex-shrink: 1;
    }

    &__input {
      margin-left: 0;
    }
  }

  .footer {
    padding: 40px 10px;

    &_grid {
      grid-template-columns: 1fr;
    }

    &_elem {
      &:first-child {
        display: none;
      }

      &_contacts {
        gap: 10px;
        margin-bottom: 10px;

        &_wrapper {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  /* Анимация появления справа */
  .fade-in-right {
    transform: translateX(15px);
  }

  /* Анимация появления слева */
  .fade-in-left {
    transform: translateX(-15px);
  }

}

@media screen and (max-width: 575px) {
  .popup {
    padding: 0;

    .form {
      padding: 30px 20px;
    }
  }

}