:root {
  --accent: #FFC90E;
  --textColor: #000;
  --gray: #777;
  --bg: #fff;
  --borderColor: #E8E8E8;
}

html {
  font-size: 15px;
  font-weight: 400;
  line-height: 134%;
  color: var(--textColor);
  background: var(--bg);
  font-style: normal;
  overflow-x: hidden;
  font-family: 'Inter', 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif;

}

// общие стили
b, strong {
  font-weight: bold;
}

section, .mb-sect {
  margin-bottom: 14.93rem;
}

// POPUP
.fancybox__content {
  border-radius: 10px;
}


img {
  width: auto;
  height: auto;
}

.mb-50 {
  margin-bottom: 3.333rem;
}

.mb-100 {
  margin-bottom: 6rem;
}

.wrapper {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 200px;
}

.wrapper__big {
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px;
  padding: 0 100px;
}

.list__gold {
  li {
    display: flex;
    align-items: center;

    &:before {
      content: "";
      width: 10px;
      height: 10px;
      background-color: var(--accent);
      border-radius: 2px;
      margin-right: .8rem;
      flex-shrink: 0;
    }
  }
}

.btn {
  text-align: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: none;
  color: #000;
  padding: 1.6rem 6rem;
  min-width: 280px;
  display: inline-block;
  background: var(--accent);
  border-radius: 60px;
  transition: all .3s;
  font-size: 1.6rem;
  font-weight: bold;

  &::after {
    top: -32px;
    content: '';
    display: block;
    -ms-transform: rotate(25deg);
    transform: rotate(25deg);
    position: absolute;
    width: 20px;
    height: 130px;
    z-index: 1;
    opacity: .55;
    background: #fff;
    -webkit-animation: 2.8s ease-out infinite btn-effect;
    animation: 2.8s ease-out infinite btn-effect;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 20px 70px rgba(231, 178, 42, 0.3);
  }
}

.title {
  text-align: center;
  font-size: 2.933rem;
  font-weight: 800;
  line-height: 113.636%;

  span {
    position: relative;
    display: inline-block;

    &:before {
      content: "";
      width: 102%;
      height: 100%;
      background-color: var(--accent);
      display: block;
      position: absolute;
      z-index: -1;
      left: -4px;
      top: 4px;
    }
  }
}

.-hide {
  display: none !important;
}

@keyframes btn-effect {
  0% {
    left: -20%
  }
  100%, 35% {
    left: 110%
  }
}

.popup {
  display: none;

  &__wrapper {
    max-width: 440px;
    width: 100%;
  }
}
.form {
  flex-direction: column;
  align-items: center;
  &__wrap {
    padding: 0;
  }
  &__inp {
    margin-top: 1.5rem;
    width: 100%;
    padding: .6rem 1rem;
    border: 1px solid var(--borderColor);
    border-radius: 20px;
    padding-left: 2rem;
  }
  &__subinp, &__politica{
    color: var(--gray);
    font-style: italic;
    font-size: .9rem;
    display: block;
    margin-top: .5rem;
  }
  &__politica a {
    text-decoration: underline;
  }

  &__btn {
    padding: 1rem 3rem;
    font-size: 1.2rem;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 1rem;
    display: block;
  }

}

// УВЕДОМЛЕНИЕ О НАЖАТИИ
.notification {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 10px;
  padding: 16px 28px;
  font-weight: 700;
  font-size: 16px;
  background-color: #e5e5e5;
  border-radius: 12px;
}


.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}


.bread {
  margin-left: calc(var(--index) * -4);
  margin-bottom: calc(var(--index) * 0.9);

  ol {
    display: flex;

    li {
      font-size: calc(var(--index) * 0.6);
      margin-right: 10px;

      &:not(:last-child):after {
        content: " – ";
        display: inline-block;
        margin-left: 5px;
      }

      a {
        color: var(--textColor);
      }
    }
  }
}

.carousel__dots {
  display: none;
}

// HEADER MENU
.header {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 2;
  background: var(--bg);
  border-bottom: 1px solid var(--borderColor);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all .3s linear;
  &__logo {
    max-width: 16.26rem;
  }

  &__burger {
    display: none;
    i {
      background: url("../images/icons/burger.webp") no-repeat center center;
      background-size: contain;
      width: 20px;
      height: 15px;
      display: block;
    }

  }

  &__links {
    gap: 0 2rem;
    display: flex;
    &_submenu {
      cursor: pointer;
      a {
        display: block;
        line-height: 140%;
      }
      li {
        position: relative;
        display: block;
        margin-bottom: .9rem;
        &:hover > ul {
          display: grid;
          left: 0;
        }
        li:hover > ul {
          left: 80%;
        }
      }
      ul {
        position: absolute;
        background-color: #f9f9f9;
        width: max-content;
        grid-template-columns: 1fr 1fr;
        gap: 0 20px;
        left: -2000px;
      }
    }
    &_first_submenu{
      top: 100%;
      padding: 1rem;
      box-shadow: 0 0 20px 1px rgb(0 0 0 / 10%);
      border-radius: 1rem;
      z-index: 1;
      grid-template-columns: 1fr 1fr;
      gap: 0 20px;
      max-height: 70vh;
    }
    &_second_submenu {
      z-index: 2;
      top: 0;
      //top: -10px;
      padding: 1rem;
      box-shadow: 0 0 20px 1px rgb(0 0 0 / 10%);
      border-radius: 1rem;
      max-width: 790px;
    }
  }

  &__link {
    padding: 2.8rem 0;
    display: block;
    cursor: pointer;
    transition: all .3s ease-in;
    border-bottom: 2px solid transparent;

    &:hover {
      border-bottom: 2px solid var(--accent);
    }
  }

  &__icons {
    display: flex;
    gap: 2.3rem;
  }

  &__phones {
    text-align: right;
  }

  &__phone {
    font-weight: bold;
    font-size: 1.6rem;
  }

  &__open_time {
    margin-top: 10px;
    display: block;
    font-size: .8rem;
    position: relative;

    &:before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      background-color: var(--accent);
      margin-right: 7px;
      border-radius: 50%;
      animation: blink 1s infinite alternate;
    }
  }
  &.scroll {
    transform: translateY(-100%);
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// ПЕРВЫЙ ЭКРАН
.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.4rem;
  margin-top: 12rem;

  &__content {


  }

  &__title {
    font-size: 5.333rem;
    line-height: 112%;
    font-weight: 800;
    margin-bottom: 4.133rem;
  }

  &__bull {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
    margin-bottom: 2.933rem;

    li {
      display: inline-block;
      position: relative;
      font-size: 1.6rem;

      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        margin-right: 1.33rem;
        width: 1.53rem;
        height: 1.46rem;
        background-image: url("../images/icons/check.webp");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }

  &__button {
    position: relative;
    display: inline-block;

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 4.46rem;
      height: 4.46rem;
      background: url("../images/icons/pointer.webp") no-repeat center center;
      background-size: contain;
      z-index: 1;
      right: -.66rem;
      top: 2.33rem;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 1.266rem;
      height: 1.733rem;
      background: url("../images/icons/calc__black.webp") no-repeat center center;
      background-size: contain;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      left: 4rem;
      transition: all .3s linear;
    }

    &:hover {
      &:before {
        transform: translateY(-60%);
      }
    }
  }

  &__image {
    width: 42%;
    flex-shrink: 0;
  }
}

// QUIZ
.quiz {
  &__wrapper {
    padding: 2.2rem 0 3.333rem;
    width: 100%;
    border-radius: 20px;
    border: 1px solid var(--borderColor);
  }
  &__wrap_js {
    display: none;
    &_active {
      display: block;
    }
  }

  &__zagolovok {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 208.333%;
    margin-bottom: 3.2rem;
    text-align: center;
    .-sub_title {
      font-size: 1.2rem;
      font-weight: 400;
      margin-top: -10px;
      display: block;
    }
  }


  &__content {
    max-width: 800px;
    gap: 2.666rem;
    margin: 0 auto;

    &_elem {
      cursor: pointer;
      position: relative;
      width: 16rem;
      padding: 1.333rem 1.333rem 2rem;
      display: block;
      border-radius: 20px;
      border: 1px solid var(--borderColor);
      min-height: 4.666rem;

      input {
        cursor: pointer;
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0;
      }
    }

    &_span {
      background: 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 30px;
        height: 30px;
        background: var(--accent);
        border: 5px solid #fff;
        box-sizing: border-box;
        box-shadow: 0 0 0 2px var(--accent);
        border-radius: 50%;
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%);
      }

      a {
        font-size: 1rem;
        line-height: 120%;
        text-align: center;
        display: block;
      }
    }

    .form__inp {
      margin-top: 0;
    }
  }
  &__text_content {
    max-width: 800px;
    gap: 2.666rem;
    margin: 0 auto;
    .form__inp {
      margin-top: 0;
      margin-bottom: 1.5rem;
    }
    .politica {
      font-size: .8rem;
      font-style: italic;
      color: var(--gray);
    }
  }
  &__btn {
    padding: 1rem;
    margin: 0 auto;
    margin-top: 2rem;
    display: table;
  }
}

// ОТЗЫВЫ
.otzivy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.6rem;

  &__content {
    max-width: 815px;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 1.4rem;
    justify-content: center;

    img {
      margin: 0;
    }
  }

  &__slider {
    position: relative;

    &__elem {
      width: var(--carousel-slide-width, 100%);
      img {
        width: auto;
        height: auto;
      }
    }


    .carousel__button {
      width: 2.4rem;
      height: 2.4rem;
      background-color: var(--borderColor);

      svg {
        width: var(--carousel-button-svg-width, 40%);
        height: var(--carousel-button-svg-height, 40%)
      }
    }
  }

  &__img {
    width: 43%;
    flex-shrink: 0;
    img {
      width: auto;
      height: auto;
    }
  }
}

// Дополнения для блкоа фелиал
.felial {
  max-width: 43rem;
  margin: 0 auto;
  display: flex;
  gap: 3.333rem;
  justify-content: center;
  align-items: center;
  padding: 2.666rem;
  border-radius: 10px;
  border: 1px solid var(--borderColor);

  &__img {
    width: 14.666rem;
    height: 14.666rem;

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  &__text {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 1.333rem;
  }

  &__title {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    margin-bottom: .666rem;
  }

  &__phone {
    margin-bottom: .666rem;
  }

  &__link {
    background: url("../images/icons/call.webp") no-repeat center center;
    background-size: contain;
    width: 15.866rem;
    height: 3.333rem;
  }
}

// ПОРТФОЛИО
.portfolio {
  &__images {
    gap: 2.666rem;
  }

  &__videos {
    margin-top: 2.666rem;
    gap: 2.666rem;
  }

  &__video {
    max-height: 30.8rem;
    border-radius: 10px;
    overflow: hidden;

    video {
      object-fit: cover;
      height: 100%;
    }
  }

  &__img {
    width: 100%;

    img {
      max-width: 100%;
      max-height: 20rem;
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
}

// СЕРТИФИКАТЫ
.sertifikaty {
  display: flex;
  justify-content: space-between;

  &__elem {
    img {
      height: 34.666rem;
    }
  }
}

// КОНТАКТЫ
.contacts {
  &__mails {
    display: flex;
    justify-content: space-between;

    &__elem {
      display: flex;
      flex-direction: column;
      gap: 1.333rem;
    }
  }

  &__title {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    margin-bottom: .666rem;
  }

  &__mail {
    display: inline-block;

    &:before {
      margin-right: 8px;
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 19px;
      height: 14px;
      background-image: url("../images/icons/mail.webp");
    }
  }

  &__phone {
    display: inline-block;

    &:before {
      margin-right: 8px;
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 19px;
      background-image: url("../images/icons/phone__gold.webp");
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7.2rem;
  }

  &__rekvizity {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .contacts__title {
      margin-bottom: 1.333rem;
    }
  }

  &__rekvizity__data {
    border-top: 1px solid var(--borderColor);
    padding-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1.333rem;

    & :nth-child(odd) {
      font-weight: bold;
    }
  }

  &__scheme {
    margin-top: 6.666rem;
  }

  &__map {
    border-radius: 10px;
    overflow: hidden;
    width: 65%;
    height: 29rem;
  }
}

// TABLE
.table {
  table {
    border-spacing: 10px;
    width: 100%;
    border-collapse: separate;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
    }

    td {
      margin: 5px;
      padding: 7px 0;
      text-align: center;
      line-height: 140%;
      padding-right: 10px;
      &:last-child {
        width: 40%;
      }

      &:first-child {
        text-align: left;
        padding-left: 27px;
        padding-right: 10px;
      }
    }

    thead {
      td {
        font-weight: 800;
      }
    }

    .-accent {
      background-color: var(--accent) !important;
      font-weight: 500;
    }

    tbody {
      tr {
        margin-bottom: .5rem;

        &:nth-child(odd) {
          background-color: var(--borderColor);
        }
      }

    }
  }
}

// КОНКУРЕНТЫ
.conkurent {
  display: flex;
  justify-content: space-between;
  gap: 11rem;
  align-items: center;

  &__img {
    flex-shrink: 0;
  }

  &__content {
    flex-grow: 1;
  }
}


/* Стили для внешнего вида */
.search {
  width: 100%;
  max-width: 760px;
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;
  height: 50px;
  margin: 0 auto;
  margin-bottom: 3.333rem;

  &__form {
    height: 100%;
    display: block;
    flex-grow: 1;
  }

  &__input {
    height: 100%;
    padding: 0;
    padding-left: 30px;
    margin: 0;
    width: 100%;
    border-radius: 25px;
    border: 1px solid var(--borderColor);
    background: #FFF;
    outline: none;
    color: var(--gray);
  }

  &__filter {
    max-width: 240px;
    padding: 0 30px;
    height: 100%;
    border-radius: 25px;
    border: 1px solid var(--borderColor);
    background: #FFF;
    outline: none;
  }
}

.suggestions {
  max-height: 210px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: 0;
  width: 70%;
  list-style: none;
  background-color: #fff;
  display: none;
  z-index: 2;
  padding: .8rem 0;
  border-radius: 25px;
  border: 1px solid var(--borderColor);

  &::-webkit-scrollbar {
    width: 6px;
    background-color: var(--borderColor);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--textColor);
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: var(--borderColor);
  }

  li {
    padding: 6px;
    padding-left: 2rem;
    cursor: pointer;
    margin-bottom: .3rem;
    transition: all .3s ease-in-out;

    &:hover {
      background-color: var(--borderColor);
    }
  }
}

.products {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.666rem;
  margin-bottom: 3.333rem;

  &__elem {
    height: 100%;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 1.333rem 2.666rem;
    border-radius: 10px;
    border: 1px solid var(--borderColor);
  }

  &__link {
    font-size: 1.6rem;
    font-weight: 800;
    line-height: 125%;
    text-align: center;
    position: relative;

    &::after {
      content: "";
      width: 100%;
      height: .466rem;
      background-color: var(--accent);
      bottom: 0;
      position: absolute;
      left: 0;
      z-index: -1;
    }
  }

  &__img {
    max-height: 240px;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__bull {
    padding-left: 1.333rem;
    margin-bottom: 2.333rem;

    li {
      margin-bottom: .5rem;
      line-height: 133.333%;
      list-style: disc;
    }
  }

  &__price {
    font-size: 1.6rem;
    text-align: center;
    font-weight: 600;
  }

  &__btn {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      padding: 1.266rem 0;
      display: block;
      font-weight: 600;
      line-height: 160%;
      text-transform: uppercase;
      width: 31rem;
      border-radius: 10px;
      background: var(--borderColor);
      border: none;
      outline: none;
      cursor: pointer;
      transition: all .3s;

      &:hover {
        transform: translateY(-3px);
        box-shadow: 3px 8px 3px 0 rgba(0, 0, 0, .14);
      }
    }
  }

}

// ТАБЛИЦА С ЦЕНАМИ
.price {
  &__subtitle {
    font-weight: 400;
    line-height: 125%;
    font-size: 1.6rem;
    width: 100%;
    text-align: center;
    color: var(--gray);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;

    &:before {
      content: "";
      background: url("../images/icons/check_gray.svg") no-repeat center center;
      background-size: contain;
      width: 24px;
      height: 22px;
      display: block;
      margin-right: 1rem;
    }
  }

  &__table {
    max-height: 27.666rem;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
      background-color: var(--borderColor);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--textColor);
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      background-color: var(--borderColor);
    }

    table {
      thead td {
        padding: 15px 0;
      }

      td {
        padding: 10px 0;

        &:first-child {
          width: 45%;
        }

        &:last-child {
          width: 20%;
        }
      }
    }
  }
}

// АКЦИИ
.akcii {
  display: flex;
  justify-content: space-between;
  gap: 8.666rem;
  align-items: center;

  &__subtitle {
    margin-top: 2rem;
    width: 100%;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 800;
    line-height: 125%;
  }

  &__img {
    flex-shrink: 0;
  }

  &__content {
    flex-grow: 1;
  }

  &__text {
    padding: 2.4rem 1.333rem 2.933rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    border-radius: 10px;
    border: 1px solid var(--borderColor);
    justify-items: center;

    a {
      text-decoration: underline;

    }
  }
}

// СКИДКИ
.skidki {
  gap: 2rem 3.333rem;

  img {
    object-fit: contain;
    height: 100%;
  }
}

// КАРТА С ГОРОДАМИ
.vyezd {
  &__content {
    display: flex;
    justify-content: space-between;
    gap: 8.333rem;
    align-items: center;
  }

  &__img {
    flex-shrink: 0;
  }

  &__text {
    flex-grow: 1;
    gap: 1rem;
    max-height: 525px;
    overflow: hidden;
  }
}

// ВАКАНСИИ
.vakancii {
  display: flex;
  justify-content: space-between;
  gap: 8rem;
  align-items: center;

  &__img {
    flex-shrink: 0;
  }

  &__content {
    flex-grow: 1;
    height: 100%;
  }

  &__list {
    &_elem {
      font-size: 1.6rem;
      margin-bottom: 1.8rem;
      display: flex;
      align-items: center;

      &:before {
        content: "";
        display: block;
        width: 24px;
        height: 22px;
        background: url("../images/icons/check.webp") no-repeat center center;
        background-size: cover;
        margin-right: 1rem;
      }
    }
  }

  &__warning {
    margin-top: 4.4rem;
    display: flex;
    gap: 2rem;
    padding: 2.666rem;
    border-radius: 10px;
    border: 1px solid var(--borderColor);
  }

  &__text {
    text-align: center;
  }

  &__phone {
    font-size: 1.6rem;
    font-weight: 800;
    margin-top: 10px;
    margin-bottom: 1.333rem;
  }
}

// ГАРАНТИИ
.garantii {
  &_content {
    display: flex;
    justify-content: center;
    gap: 80px;
    margin-bottom: 60px;


    ul {
      li {
        margin-bottom: 1rem;
        line-height: 140%;

        img {
          margin-top: 8px;
          margin-left: 30px;
        }
      }
    }

    .-img {
      width: 500px;
    }
  }
}

// РАССРОЧКА
.rassrochka {
  &__content {
    display: flex;
    justify-content: space-between;
    gap: 8rem;
    align-items: center;
  }

  &__img {
    flex-shrink: 0;
  }

  &__text {
    flex-grow: 1;
    height: 100%;
  }

  &__title {
    font-size: 1.6rem;
    font-weight: 800;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;

    &:before {
      content: "";
      background: url("../images/icons/warning.webp") no-repeat center center;
      background-size: contain;
      width: 32px;
      height: 32px;
      margin-right: 1.6rem;
      flex-shrink: 0;
    }
  }
  &__list {
    padding-left: 5rem;
    li {
      margin-bottom: 1.6rem;
    }
  }
}

// ВОПРОСЫ
.questions{
  &__content {
    display: flex;
    justify-content: center;
  }
  &__img {
    flex-shrink: 0;
    margin-top: .5rem;
  }
  &__input{
    flex-grow: 1;
    margin-left: 2rem;
  }
  &__textfield{
    padding: .8rem 1rem;
    width: 100%;
    border-radius: 12px;
    border: 2px solid var(--accent);
  }
  &__subtitle{
    color: var(--gray);
    margin-top: .8rem;
    display: block;
    padding-left: 1rem;
  }
  &__list{
    margin-top: 1.6rem;
    padding-left: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: .8rem 2rem;
    max-height: 124px;
    overflow: hidden;
  }
  &__elem {
    color: #245FB8;
  }
}

.footer {
  padding: 80px 0 50px;
  &_bg {
    background-color: #F7F7F7;
  }

  &_grid {
    justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 4rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #dfdfdf;;
  }

  &_elem {
    &_like {
      border: 4px dashed var(--accent);
      box-sizing: border-box;
      border-radius: 10px;
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
    }

    &_contacts {
      display: flex;
      align-items: center;
      justify-content: start;

      &_wrapper {
        display: grid;
        grid-template-columns: 1.7fr 1fr 1fr;
        align-items: start;
        justify-content: start;
        gap: 20px 40px;
        flex-wrap: wrap;
      }


      b {
        display: block;
        margin-bottom: .5rem;
      }

      img {
        margin: 0;
        margin-right: 10px;
        width: 24px;
        height: 24px;
        object-fit: contain;
        flex-shrink: 0;
      }
    }

    &_links {
      a {
        margin-bottom: 14px;
        color: #000;
        text-decoration: underline;
        display: block;
      }

      img {
        margin: 0;
        margin-top: 16px;
      }
    }
  }

  &_prav {
    padding-top: 30px;
    color: #999;
    font-size: 14px;
    line-height: 140%;
  }
}

.komanda {
  display: none;
}

/* Общий стиль для скрытых элементов */
.hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  transition-delay: var(--delay, 0s); /* Используем CSS переменную для задержки */
}

/* Анимация появления сверху */
.fade-in-top {
  transform: translateY(-20px);
}

/* Анимация появления справа */
.fade-in-right {
  transform: translateX(20px);
}

/* Анимация появления слева */
.fade-in-left {
  transform: translateX(-20px);
}

/* Анимация появления снизу */
.fade-in-bottom {
  transform: translateY(20px);
}

/* Анимация fade-in при становлении видимым */
.visible {
  visibility: visible;
  opacity: 1;
  transform: translateX(0) !important;
}

@import "media";